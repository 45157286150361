import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from './apiClient';

const CallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          await apiClient('/auth/callback', {
            method: 'POST',
            body: { code },
          });
          console.log('API call successful');
          navigate('/dashboard'); // 로그인 후 페이지로 이동
        } catch (error) {
          console.error('Authentication error:', error);
        }
      }
    };

    handleAuth();
  }, []); // navigate를 의존성 배열에서 제거

  return <div>Processing login...</div>;
};

export default CallbackPage;
