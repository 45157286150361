// apiClient.js
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL || 'https://dev-auth.emotioncastle.com';

const apiClient = (endpoint, options = {}) => {
  return axios({
    url: `${baseUrl}${endpoint}`,
    method: options.method || 'GET',
    data: options.body, // POST/PUT/PATCH 요청일 때 전송할 데이터
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
    withCredentials: true, // HTTP-Only 쿠키 전송 허용
  })
    .then(response => response.data) // 응답 데이터를 반환
    .catch(error => {
      throw new Error('API request failed');
    });
};

export default apiClient;
