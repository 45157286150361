// DashboardPage.js
import React, { useState, useEffect } from 'react';
import apiClient from './apiClient.js';

const DashboardPage = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    apiClient('/user/profile')
      .then(data => setUserData(data))
      .catch(error => console.error('Error fetching user data:', error));
  }, []);

  return (
    <div>
      <h2>Dashboard</h2>
      {userData ? (
        <div>
          <p>Welcome, {userData.name}</p>
          <p>Email: {userData.email}</p>
        </div>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};

export default DashboardPage;
