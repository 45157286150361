// LoginPage.js
import React from 'react';

const LoginPage = () => {
  const handleLogin = () => {
    // Keycloak 로그인 페이지로 리디렉션
    const clientId = 'test2.emotioncastle.com-client';
    const redirectUri = encodeURIComponent(window.location.origin + '/callback');
    const keycloakAuthUrl = `https://dev-auth.emotioncastle.com/realms/samg/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
    
    window.location.href = keycloakAuthUrl;
  };

  return (
    <div>
      <h2>Login Page</h2>
      <button onClick={handleLogin}>Login with Keycloak</button>
    </div>
  );
};

export default LoginPage;
